import React, { type FC } from "react";

import { DontHydrate, hastToReact } from "@AuxiliaryComponents";
import { Paragraph, keyboardOutline } from "@Components";
import type { Colors } from "@Tokens";
import type { PromoCampaignHeaderSection } from "./types";

type MultiPromotionTileCtaProps = PromoCampaignHeaderSection & {
	className?: string;
};

export const MultiPromotionTileCta: FC<MultiPromotionTileCtaProps> = ({
	text,
	color: mayBeColor,
	className,
}) => {
	const color = (mayBeColor as keyof Colors) ?? "backgroundWhite";

	return (
		<DontHydrate
			as="span"
			data-id="multi-promo-cta"
			className={`${className} multiPromoCta`}
			sx={{
				display: "inline-block",
				fontSize: "m",
				textDecoration: "none",
				paddingBottom: "3xs",
				borderBottomWidth: "outlinedStrokeWeight",
				borderBottomStyle: "solid",
				borderBottomColor: color,
				position: "relative",
				color,

				"&:visited": {
					color,
				},

				"&:hover::after": {
					width: "100%",
				},

				"&::after": {
					content: '""',
					height: "2px",
					width: 0,
					left: 0,
					bottom: 0,
					position: "absolute",
					transition: "width 0.2s linear",
					backgroundColor: color,
				},
				...keyboardOutline,
			}}
		>
			{hastToReact(text, {
				p: {
					Component: Paragraph,
					Props: {
						variant: "small",
						sx: { color: (color as keyof Colors) ?? "white" },
					},
				},
			})}
		</DontHydrate>
	);
};
