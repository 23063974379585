import React, { type FC } from "react";

import { hastToReact } from "@AuxiliaryComponents";
import { Paragraph, Title } from "@Components";
import type { ColorsPalette, ThemeDefinition } from "@Themes";
import type { ParagraphVariants, TitleVariants } from "@Tokens";
import { MultiPromotionTileCta } from "./MultiPromotionTileCta";
import type { PromoCampaignHeader } from "./types";
import type { TileVariant } from "./utils";

const titleFontSizeMap: Record<TileVariant, TitleVariants> = {
	large: "medium",
	medium: "xsmall",
	small: "xxsmall",
};

const subtitleFontSizeMap: Record<TileVariant, ParagraphVariants> = {
	large: "large",
	medium: "medium",
	small: "medium",
};

type MultiPromotionTileHeaderProps = PromoCampaignHeader & {
	variant: TileVariant;
};

export const MultiPromotionTileHeader: FC<MultiPromotionTileHeaderProps> = ({
	variant,
	title,
	subtitle,
	cta,
}) => (
	<div>
		{title?.text && (
			<Title
				as={variant === "large" ? "h1" : "h2"}
				variant="xxsmall"
				sx={{ marginBottom: "3xs" }}
			>
				{hastToReact(title.text, {
					p: {
						Component: Paragraph,
						Props: {
							sx: (theme: ThemeDefinition) => ({
								fontSize:
									theme.typography.title[titleFontSizeMap[variant]].fontSize,
								lineHeight: 1.125,
								fontWeight: "normal",
								display: "block",
								color: (title.color as keyof ColorsPalette) ?? "white",
							}),
							as: "span",
						},
					},
				})}
			</Title>
		)}

		{subtitle?.text &&
			hastToReact(subtitle.text, {
				p: {
					Component: Paragraph,
					Props: {
						variant: "small",
						sx: (theme: ThemeDefinition) => ({
							fontSize:
								theme.typography.paragraph[subtitleFontSizeMap[variant]]
									.fontSize,
							color: (subtitle.color as keyof ColorsPalette) ?? "white",
							lineHeight: 1.125,
						}),
					},
				},
			})}

		{cta?.text && <MultiPromotionTileCta sx={{ marginTop: "xs" }} {...cta} />}
	</div>
);
